<template>
  <form>
    <v-row class="py-0">
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <form-edit
          :form="driverKasbon"
          :employees="employees"
          :listAccountNumber="listAccountNumber"
          :listCategory="listCategory"
          :dropDownCurrency="dropDownCurrency"
          ref="formEdit"
          v-if="!edit"
        ></form-edit>
        <detail
          :id="id"
          :form="driverKasbon"
          :listAccountNumber="listAccountNumber"
          v-if="edit"
        ></detail>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="updateItem">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit && driverKasbon.status === 'Outstanding'">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="editItem">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit && driverKasbon.status === 'Outstanding'">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click.stop="deleteDialog">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="!edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="error" v-on="on" @click="cancel">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="py-0 mb-2" v-if="edit">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-delete
      :id="id"
      :dialog="dialog"
      :invoiceNumber="driverKasbon.formNumber"
      title="Driver Kasbon"
      @deleteItem="deleteItem"
      @close="close"
    ></dialog-delete>
  </form>
</template>

<script>
import Detail from "./DetailKasbonDriver";
import FormEdit from "./FormKasbonDriver";
import DialogDelete from "@/components/DialogDelete";
import { mapState } from "vuex";

export default {
  name: "driver-kasbon-view",
  components: {
    Detail,
    "form-edit": FormEdit,
    "dialog-delete": DialogDelete,
  },
  props: {
    id: {
      type: Number,
      default: () => undefined,
    },
  },
  data: () => ({
    edit: true,
    dialog: false,
    listCategory: [],
    employees: [],
    driverKasbon: {},
  }),
  computed: {
    ...mapState("currency", ["dropDownCurrency"]),
    ...mapState("generalKasbon", ["listAccountNumber"]),
  },
  methods: {
    async getDriverKasbon() {
      await this.$store.dispatch("driverKasbon/getById", this.id).then(async response => {
        this.driverKasbon = response.data;
        await this.$store.dispatch("generalKasbon/getListAccountNumber", response.data.employeeId);
      });
    },
    async getEmployee() {
      await this.$store
        .dispatch("external/getEmployees")
        .then(response => (this.employees = response.data));
    },
    async getCategory() {
      const response = await this.$store.dispatch("generalKasbon/getListCategory");
      if (response.status === 200) {
        this.listCategory = response.data;
      }
    },
    back() {
      this.$router.go(-1);
    },
    editItem() {
      this.edit = false;
    },
    updateItem() {
      this.$refs.formEdit.update();
    },
    deleteItem(id) {
      this.$store.dispatch("driverKasbon/delete", id);
      this.dialog = false;
    },
    deleteDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    cancel() {
      this.getGeneralKasbon();
      this.edit = true;
    },
    async getInitialData() {
      Promise.all([
        this.getCategory(),
        this.getEmployee(),
        this.getDriverKasbon(),
        await this.$store.dispatch("currency/getListCurrency"),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>

<style></style>
